// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import jogos from '@src/views/apps/resultados/store'

const rootReducer = { 
  auth,
  jogos, 
  navbar,
  layout
}

export default rootReducer
