// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

const api = axios.create({
  //baseURL: 'http://localhost/bms-api/public/api/'
  baseURL: 'https://api.seninha.bet/api/'
})

export const getData = createAsyncThunk('/rankgingeralbyjogo/', async params => {
  // filter={"q":"batista","user":{"name":true},"jogo":{"nome":"SETEMBRO"}}

  console.log(params)
  const response = await api.get(`/rankgingeralbyjogo/${params.jogo_id}?page=${params.page}&filter={'q':${params.q}}`, params)
  return {
    params,
    data: response.data.data, 
    allData: response.data.data,
    totalPages: response.data.total
  }
})


export const colunasPremio5 = [
  {
    name: 'CÓD',
    maxWidth: '100px',
    selector: row => row.id
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.user.name
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.jogo.nome
  },
  {
    name: 'Valor',
    minWidth: '225px',
    selector: row => row.concurso.percentual_5
    //((row.concurso.premio_garantido * row.concurso.percentual_5) / 100) / row.
  }
]

export const colunasPremio6 = [
  {
    name: 'CÓD',
    maxWidth: '100px',
    selector: row => row.id
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.user.name
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.jogo.nome
  },
  {
    name: 'Valor',
    minWidth: '225px',
    selector: row => row.concurso.percentual_6
  }
]

export const colunasPremio7 = [
  {
    name: 'CÓD',
    maxWidth: '100px',
    selector: row => row.id
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.user.name
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.jogo.nome
  },
  {
    name: 'Valor',
    minWidth: '225px',
    selector: row => row.concurso.percentual_7
  }
]

export const colunasPremio8 = [
  {
    name: 'CÓD',
    maxWidth: '100px',
    selector: row => row.id
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.user.name
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.jogo.nome
  },
  {
    name: 'Valor',
    minWidth: '225px',
    selector: row => row.concurso.percentual_8
  }
]

export const colunasPremio9 = [
  {
    name: 'CÓD',
    maxWidth: '100px',
    selector: row => row.id
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.user.name
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.jogo.nome
  },
  {
    name: 'Valor',
    minWidth: '225px',
    selector: row => row.concurso.percentual_9
  }
]

export const colunasPremio10 = [
  {
    name: 'CÓD',
    maxWidth: '100px',
    selector: row => row.id
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.user.name
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.jogo.nome
  },
  {
    name: 'Valor',
    minWidth: '225px',
    selector: row => row.concurso.percentual_5
  }
]

export const basicColumns = [
  {
    name: 'CÓD',
    maxWidth: '100px',
    selector: row => row.id
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.user.name
  },
  {
    name: 'Name',
    minWidth: '225px',
    selector: row => row.jogo.nome
  },
  {
    name: 'Valor',
    minWidth: '225px',
    selector: row => row.concurso.percentual_5
  }
]


export const appInvoiceSlice = createSlice({
  name: 'appInvoice',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
    })
  }
})

export default appInvoiceSlice.reducer
